<template>
  <div>
    <div class="" style="width: 100%">
      <img style="display: block;width: 1920px;margin: auto;" src="../../assets/images/djt/zxgfsx.jpg" alt="">
    </div>
    <div class="bg">
      <div class="wrapper">
        <div>
          <div _ngcontent-c6="" class="page1">
            <h2 _ngcontent-c6="" class="center">在线攻防实训</h2>
            <p _ngcontent-c6="" class="em2">交通运输行业网络安全大讲堂在线攻防实训平台，根据网络空间安全知识基础广博、知识体系庞杂、学科交叉性强的特点，由资深网络安全专家团队梳理出逻辑紧密、结构清晰、层次分明、全面覆盖的网络空间安全知识体系后搭建，是面向网络安全从业人员的专业实操实训平台。</p>
            <p _ngcontent-c6="" class="em2 bold">功能介绍</p>
            <p _ngcontent-c6="" class="em2">（1）课程资源</p>
            <p _ngcontent-c6="" class="em2">每一门课程包含实验、视频、学习指导、考核标准、教学进度、教学指导、教学设计评价等内容；每个实验包含指导书、课程资料等内容；指导包含实验目的、实验原理、实验内容、实验环境描述、实验步骤；</p>
            <div _ngcontent-c6="" class="img"><img _ngcontent-c6="" alt="" src="../../assets/images/djt/gfsx1.png"></div>
            <p _ngcontent-c6="" class="em2">（2）资源列表</p>
            <div _ngcontent-c6="" class="img"><img _ngcontent-c6="" alt="" src="../../assets/images/djt/gfsx2.png"></div>
            <p _ngcontent-c6="" class="em2">（3）实验管理</p>
            <p _ngcontent-c6="" class="em2">支持查看平台所有实验，包含实验名称、编号、讲师、管理员、用户数量、课件数量；支持对实验的知识点、课件等进行编辑；支持实验数据导出，虚拟镜像数据导出；平台支持实验设计，用户可以个性化编辑上传实验内容，课件支持SCORM标准课件、压缩包、音视频等格式；</p>
            <div _ngcontent-c6="" class="img"><img _ngcontent-c6="" alt="" src="../../assets/images/djt/gfsx3.png"></div>
            <p _ngcontent-c6="" class="em2">（4）选课中心</p>
            <p _ngcontent-c6="" class="em2">管理员可自由对课程内容进行设计；支持按班级、用户、课程进行授权；支持课程管理，课程管理支持课程体系、分类设置与管理；支持查看平台所有课程；支持对课程名称、上级分类、课程图片、课程介绍、课程标准、考核标准、教学进度、学习指导、教学指导、教学评价与设计等内容进行编辑、设置；</p>
            <div _ngcontent-c6="" class="img"><img _ngcontent-c6="" alt="" src="../../assets/images/djt/gfsx4.png"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</template>

<style lang="less" scoped>
  .bold {
    font-weight: bold;
  }
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2{
  text-indent: 2em;
  line-height: 40px;
}
.center{
  text-align: center;
}
.img {
  text-align: center;
  img {
    width: 100%;
  }
  // width: 100%;
  // display: flex;
  // padding: 10px 0;
  // align-items: center;
  // justify-content: center;
}
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
